<template>
  <div style="display: flex">
    <div class="systemList" style="width: 8%; height: 88vh">
      <div class="title">
        <div class="span"></div>
        选择会员体系
      </div>
      <div style="min-height: 500px; max-height: 600px; overflow-y: auto">
        <div
          class="store"
          v-for="(item, index) in systemList"
          v-bind:key="index"
          :class="
            client_system_id === item.client_system_id ? 'selectStore' : null
          "
          @click="getClientSystemHierarchy(item)"
        >
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="storeList" style="width: 8%">
      <div class="title">
        <div class="span"></div>
        选择门店
      </div>
      <div style="min-height: 500px; max-height: 600px; overflow-y: auto">
        <div
          class="store"
          v-for="(item, index) in storeList"
          v-bind:key="index"
          :class="hierarchy_id === item.hierarchy_id ? 'selectStore' : null"
          @click="handleStore(item)"
        >
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div style="width: 83%">
      <div
        class="content"
        style="display: flex; height: 50px; line-height: 50px"
      >
        <div>
          <span style="margin-left: 32px; margin-right: 20px"
            >是否开启客服</span
          >
          <el-switch
            :active-value="20"
            :inactive-value="10"
            v-model="chat_open_server"
            @change="submitCustomer"
          />
        </div>
        <div style="margin-left: 30px">客服链接:</div>
        <div
          style="
            display: flex;
            width: 300px;
            height: 30px;
            line-height: 30px;
            margin: 10px 20px 0 10px;
          "
        >
          <div class="link">
            {{ chat_server_link }}
          </div>
          <div
            class="copy"
            data-clipboard-text="复制"
            @click="copylogistics(chat_server_link)"
          >
            复制
          </div>
        </div>
        <a href="https://mp.weixin.qq.com/" target="_blank" class="config"
          >去配置公众号</a
        >
      </div>
      <div
        class="content"
        style="margin-top: 10px; padding: 10px 0; height: 82vh"
      >
        <div style="margin: 0 0 10px 20px">
          <!-- <el-checkbox
            v-model="chat_is_follwer"
            :true-label="20"
            :false-label="10"
            style="margin-right: 8px"
          ></el-checkbox> -->
          优先跟进人<span
            style="
              font-size: 12px;
              color: #fd563a;
              width: 60%;
              margin: 9px auto 0 auto;
            "
          >
            可拖拽行改变列表排序
          </span>
        </div>
        <ux-grid
          ref="plxTable"
          style="width: 98%; margin: 0 auto"
          max-height="700"
          :data="userList"
          row-key
          border
        >
          <ux-table-column
            type="index"
            title="序号"
            width="80"
            align="center"
          ></ux-table-column>
          <ux-table-column
            title="员工"
            field="realname"
            align="center"
          ></ux-table-column>
          <ux-table-column title="默认客服" field="is_server" align="center">
            <template slot-scope="scope">
              <el-switch
                :active-value="20"
                :inactive-value="10"
                v-model="scope.row.is_server"
              />
            </template>
          </ux-table-column>
        </ux-grid>
        <div style="text-align: right; margin: 10px 14px 0 0">
          <el-button type="primary" plain>取消</el-button>
          <el-button type="primary" @click="submitCustomer">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard"; //点击复制文本内容

import Sortable from "sortablejs/modular/sortable.core.esm.js";

import { getBindingClientSystemList } from "@/api/member/system/Index.js";
import { getClientSystemHierarchyForIDReq } from "@/api/marketing/coupon/index.js";
import { getUserList, setServer } from "@/api/member/customerService/index.js";
export default {
  data() {
    return {
      systemList: [],
      storeList: [],
      client_system_id: null,
      hierarchy_id: null,
      chat_open_server: null,
      // chat_is_follwer: null,
      userList: [],
      tableVisiable: true,
      chat_server_link: null,
    };
  },
  created() {
    this.getBindingClientSystemList();
  },
  watch: {
    tableVisiable: {
      handler(val) {
        if (val) {
          this.columnDrop();
        }
      },
      immediate: true,
    },
  },
  methods: {
    //复制功能
    copylogistics(data) {
      let clipboard = new Clipboard(".copy", {
        //绑定数据标签的类名
        text: function () {
          return data;
        },
      });
      clipboard.on("success", (e) => {
        this.$message({
          type: "success",
          message: "复制成功！",
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({
          type: "success",
          message: "该浏览器或手机权限不支持复制功能",
        });

        // 释放内存
        clipboard.destroy();
      });
    },
    /*
     *  会员体系
     */
    getBindingClientSystemList() {
      getBindingClientSystemList().then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.systemList = res.data;
            this.client_system_id = res.data[0].client_system_id;
            this.chat_server_link =
              res.data[0].chat_server_link + "/customer/storeList";
            this.getClientSystemHierarchy(res.data[0]);
          }
        }
      });
    },
    getClientSystemHierarchy(item) {
      this.chat_server_link = item.chat_server_link + "/customer/storeList";
      this.client_system_id = item.client_system_id;
      getClientSystemHierarchyForIDReq({
        client_system_id: item.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
          this.hierarchy_id = res.data[0].hierarchy_id;
          this.chat_open_server = res.data[0].chat_open_server;
          // this.chat_is_follwer = res.data[0].chat_is_follwer;
          this.getUserList();
        }
      });
    },
    getUserList() {
      getUserList({ hierarchy_id: this.hierarchy_id }).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.userList = res.data;
          } else {
            this.userList = [];
          }
        }
      });
    },
    handleStore(item) {
      this.chat_open_server = item.chat_open_server;
      // this.chat_is_follwer = item.chat_is_follwer;
      this.hierarchy_id = item.hierarchy_id;
      this.getUserList();
    },
    columnDrop() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[0]
          .getElementsByTagName("tbody")[0];

        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.userList.splice(oldIndex, 1)[0];
            this.userList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    submitCustomer() {
      const store_user_set_list = this.userList.map((item, index) => {
        return {
          store_user_id: item.store_user_id,
          is_server: item.is_server,
          server_sort: index + 1,
        };
      });
      const data = {
        chat_open_server: this.chat_open_server,
        // chat_is_follwer: this.chat_is_follwer,
        hierarchy_id: this.hierarchy_id,
        store_user_set_list: JSON.stringify(store_user_set_list),
      };
      setServer(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          getClientSystemHierarchyForIDReq({
            client_system_id: this.client_system_id,
          }).then((res) => {
            if (res.code === 1) {
              this.storeList = res.data;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.systemList {
  padding-top: 22px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.storeList {
  padding-top: 22px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.store {
  padding-left: 17px;
  width: 100%;
  background: white;
  padding: 10px 0;
  font-size: 14px;

  .text {
    margin-left: 15px;
  }
}
.store:hover {
  cursor: pointer;
}

.selectStore {
  background-color: #ecf5ff;
  .text {
    margin-left: 14px;
  }
}
.title {
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  font-size: 16px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.link {
  width: 345px;
  padding-left: 5px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px 0px 0 5px;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.copy {
  width: 60px;
  opacity: 1;
  background: rgba(39, 145, 255, 0.06);
  border: 1px solid #2791ff;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: #2791ff;
}
.copy:hover {
  cursor: pointer;
}
.config {
  margin: 10px 0 0 0;
  width: 110px;
  height: 30px;
  background: #2791ff;
  color: white;
  border-radius: 5px;
  line-height: 30px;
  text-align: center;
}
a {
  text-decoration: none;
}
</style>
