import { post, get } from '@/utils/request';

// 员工列表
const getUserList = (data) => post({
    url: '/store/customer.ChatServer/getUserList',
    data: data
});

// 设置会员客服
const setServer = (data) => post({
    url: '/store/customer.ChatServer/setServer',
    data: data
})

export {
    getUserList,
    setServer
}